.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
}

.terminal-ui {
  width: 90%;
  height: 80%;
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  text-align: left;
  padding: 0 20px;
  white-space: pre-wrap;
  word-break: break-word;
}

.rainbow-text {
  background: linear-gradient(90deg, #f22, #f2f, #22f, #2ff, #2f2, #ff2, #f22);
  -webkit-background-clip: text;
  color: transparent; /* Hide the original text color */
  display: inline-block; /* Necessary for gradients to apply correctly */
}

.username {
  color: limegreen;
}

.path {
  color: blue;
}

.title {
  color: blueviolet;
}

.terminal-output {
  white-space: pre-wrap; /* Allows text to wrap and preserves whitespace */
  word-break: break-word; /* Ensures words break to prevent horizontal scrolling */
}
